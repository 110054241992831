<template>
  <CenterCenter>
    <div class="main">
      <perfect-scrollbar ref="scrollPanel">
        <div class="logoImage"></div>
        <div class="titleCreateAccount">
          {{ $t('signUp.title') }}
        </div>
        <ExternalSignIn class="externalSign" :signIn="false" :display-apple-button="false" @close="close"/>
        <w-divider :label="$t('signUp.orManualSignIn')"/>
        <w-error class="errorMessage" :message="errorMsg"/>
        <form class="addUser" ref="scrollAnchor">

          <w-input :label="$t('signUp.email')" @focus="activateAuthManual" @blur="fillprofil" required v-model="email"
                   :placeholder="$t('signUp.emailPlaceholder')" data-test="signUpEmailInput" name="email"
          />
          <Transition name="fade">
            <w-input :label="$t('signUp.firstname')" required @keydown.native.enter="createAccount"
                     v-model="firstname" v-show="manualAuth && false" name="firstname"
                     :placeholder="$t('signUp.firstnamePlaceholder')" data-test="signUpFirstnameInput"/>
          </Transition>
          <Transition name="fade">
            <w-input :label="$t('signUp.lastname')" required @keydown.native.enter="createAccount"
                     v-model="lastname" name="lastname"
                     :placeholder="$t('signUp.lastnamePlaceholder')" data-test="signUpLastnameInput"
                     v-show="manualAuth && false"/>
          </Transition>
          <Transition name="fade">
            <w-input :label="$t('signUp.password')" required v-model="password" @keydown.native.enter="createAccount"
                     data-test="signUpPasswordInput"
                     type="password" v-show="manualAuth|| true" name="password"/>
          </Transition>
          <Transition name="fade">
            <div class="passwordInformation" v-show="password.length>0">
              <div class="passwordTitle">{{ $t('signUp.rules.title') }}</div>
              <div class="tagWeak" :class="computePasswordStrengthClass">{{ computePasswordStrength }}</div>
              <ul>
                <li>{{ $t('signUp.rules.length', {length: 8}) }}</li>
                <li>{{ $t('signUp.rules.uppercase', {length: 1}) }}</li>
                <li>{{ $t('signUp.rules.digit', {length: 1}) }}</li>
              </ul>
            </div>
          </Transition>
        </form>
        <div class="description" data-test="signUpCguLink" v-show="manualAuth">
          {{ $t('signUp.cguDescription') }}
          <a target="_blank"
             href="https://weet.co/terms-of-service/">
            {{ $t('signUp.cgu') }}
          </a>
        </div>
        <div class="containerButton">
          <w-button expanded color="primary" :loading="loading" class="buttonSign" @click="createAccount"
                    data-test="signUpValidateButton">
            {{ $t('signUp.button.signup') }}
          </w-button>
        </div>
        <div class="alreadyAccountLink" data-test="signUpSignInLink">
          {{ $t('signUp.alreadyHaveAnAccount') }}
          <a @click="toSignin()">{{ $t('login.button.signin') }}</a>
        </div>
      </perfect-scrollbar>
    </div>
  </CenterCenter>
</template>

<script lang="ts">
import CenterCenter from '@/components/layout/CenterCenter.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WError from '@/components/wrapper/w-error.vue';
import WInput from '@/components/wrapper/w-input.vue';
import router from '@/router';
import {capitalize, isEmail} from '@/utils/util';
import {User} from '@/store/user/userModel';
import store from '@/store';
import {CREATE_ACCOUNT_USER} from '@/store/auth/authAction';
import ExternalSignIn from '@/components/authPanel/ExternalSignIn.vue';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import WDivider from '@/components/wrapper/w-divider.vue';
import VueScrollTo from 'vue-scrollto';
import {closeWModal} from '@/utils/dialog';
import {redirectAfterAccountCreation} from '@/utils/loginUtil';
import WIcon from '@/components/wrapper/w-icon.vue';
import {passwordStrength} from 'check-password-strength';

@Component({
  components: {WIcon, WDivider, ExternalSignIn, WInput, WError, PopUpTitle, WButton, MainTitle, CenterCenter},
})
export default class SignUp extends Vue {
  private email: string = '';
  private firstname: string = '';
  private lastname: string = '';
  private password: string = '';
  private errorMsg: string = '';
  private loading: boolean = false;
  private manualAuth: boolean = false;

  @Prop({default: false})
  private popIn!: boolean;


  @Prop({default: true})
  private canCancel!: boolean;

  public mounted() {
    setPageEvent(PageEvent.page_account_creation);
  }

  /// Close the popup if need
  private close() {
    if (this.canCancel) {
      closeWModal(this);
    }
    this.$emit('close');
  }

  private createAccount() {
    this.fillprofil();
    this.errorMsg = '';
    this.loading = true;
    const user = new User(this.email, this.firstname, this.lastname, this.email, '');
    const password = this.password;
    store.dispatch(CREATE_ACCOUNT_USER, {user, password}).then(async (resp) => {
      this.errorMsg = '';
      setActionEvent(ActionEvent.account_creation_manual);
      await redirectAfterAccountCreation();
      this.close();
    }).catch((err) => {
      this.loading = false;
      this.errorMsg = err;
    });
  }

  private toSignin() {
    if (!this.popIn) {
      router.push({name: 'Authentication'});
    }
    this.$emit('signIn');
  }

  private srollToButton() {
    // @ts-ignore
    const panel = this.$refs.scrollPanel.$el as HTMLDivElement;
    const scrollAnchor = this.$refs.scrollAnchor as HTMLDivElement;
    const options = {
      container: panel,
      easing: 'ease-in-out',
      lazy: true,
      offset: 0,
      force: true,
      cancelable: true,
      onStart: (element) => {
        // scrolling started
      },
      onDone: (element) => {
        // scrolling is done
      },
      onCancel: () => {
        // scrolling has been interrupted
      },
      x: false,
      y: true
    };
    // we have to wait the panel is open in fullscreenmode
    setTimeout(() => {
      VueScrollTo.scrollTo(scrollAnchor, 1000, options);
    }, 100);
  }


  private activateAuthManual() {
    if (!this.manualAuth) {
      this.srollToButton();
    }
    this.manualAuth = true;
  }

  get computePasswordStrength(): string {
    const value = passwordStrength(this.password).id;
    switch (value) {
      case 0: {
        return this.$t('generic.password.weak').toString();
      }
      case 1: {
        return this.$t('generic.password.good').toString();
      }
      case 2: {
        return this.$t('generic.password.strong').toString();
      }
      case 3: {
        return this.$t('generic.password.verystrong').toString();
      }
    }
    return '';
  }

  get computePasswordStrengthClass(): string {
    return passwordStrength(this.password).value.toLowerCase().replaceAll(' ', '');
  }

  private fillprofil() {
    if (isEmail(this.email)) {
      const email: string[] = this.email.split('@');
      const firstPart = email[0];
      const domain = email[1].split('.')[0];

      if (firstPart.indexOf('.') > -1 || firstPart.indexOf('_') > -1) {
        const fullname: string[] = email[0].split('.');
        const firstname: string = fullname[0];
        const lastname: string = fullname[1];

        if (this.firstname === '') {
          this.firstname = capitalize(firstname);
        }
        if (this.lastname === '') {
          this.lastname = capitalize(lastname);
        }
      } else {
        if (this.firstname === '') {
          this.firstname = capitalize(firstPart);
        }
        if (this.lastname === '') {
          this.lastname = capitalize(domain);
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/gridBreakPoint.scss";

.main {
  max-width: 512px;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background: #FFF;
  border-radius: 8px;
  margin: auto;
  max-height: 1000px;

  .ps {
    max-height: 100%;
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 32px;
    padding-bottom: 48px;
    @include GRID1X {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .passwordInformation {
    animation-duration: 0.2s;
    background: var(--light);
    border-radius: 16px;
    margin-top: 8px;
    padding: 16px;
    color: var(--dark);
    font-size: 14px;
    text-align: left;
    position: relative;

    .passwordTitle {
      font-weight: 900;
    }

    li {
      list-style-type: disc;
      list-style-position: inside;
    }

    .tagWeak {
      position: absolute;
      top: 16px;
      right: 16px;
      color: var(--dark);
      font-size: 12px;
      font-weight: 900;
      border-radius: 16px;
      padding: 1px 8px;

      &.tooweak {
        background: lightyellow;
      }

      &.weak {
        background: yellowgreen;
      }

      &.strong {
        background: greenyellow;
      }
    }
  }

  .logoImage {
    background: url(@/assets/logo/weet_h_gradiant.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 42px;
    margin: auto;
  }

  .titleCreateAccount {
    margin-top: 16px;
    font-weight: 900;
    font-size: 24px;
  }

  .externalSign {
    margin-top: 32px;
  }

  .errorMessage {
    margin-top: 16px;
    margin-bottom: 16px;
  }

}

.description {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: var(--light2);
}

.alreadyAccountLink {
  width: 100%;
  font-size: 13px;
  font-weight: 900;
  margin-top: 24px;
  letter-spacing: 0.3px;
  color: var(--black);
  text-transform: uppercase;
}

form {
  text-align: left;
  width: 100%;
}

.addUser {
  margin-top: 16px;
}

.containerButton {
  margin-top: 24px;
}

.buttonLogin {
  margin-top: 32px;
}


</style>
